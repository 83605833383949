import React, { useEffect, useRef, useState, useCallback } from 'react';
import { BrowserRouter, Routes, Route, Navigate, useParams, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import './i18n';
import { Helmet, HelmetProvider } from 'react-helmet-async';

// eslint-disable-next-line no-unused-vars
const updateMetaTag = () => {
  // ... function code ...
};

// 语言配置
const languages = [
  { code: 'en', name: 'English', nativeName: 'English' },
  { code: 'zh', name: '中文', nativeName: '中文' },
  { code: 'ja', name: '日本語', nativeName: '日本語' },
  { code: 'ko', name: '한국어', nativeName: '한국어' },
  { code: 'it', name: 'Italian', nativeName: 'Italiano' },
  { code: 'fr', name: 'French', nativeName: 'Français' },
  { code: 'es', name: 'Spanish', nativeName: 'Español' },
  { code: 'ru', name: 'Russian', nativeName: 'Русский' },
  { code: 'th', name: 'Thai', nativeName: 'ไทย' }
];

// 语言切换器组件
const LanguageSwitcher = ({ currentLang, onLanguageChange }) => {
  const [isOpen, setIsOpen] = React.useState(false);
  const currentLanguage = languages.find(lang => lang.code === currentLang);

  return (
    <div style={{
      position: 'absolute',
      top: '10px',
      left: '10px',
      zIndex: 2
    }}>
      <div 
        onClick={() => setIsOpen(!isOpen)}
        style={{
          backgroundColor: 'rgba(255, 255, 255, 0.1)',
          padding: '8px 12px',
          borderRadius: '12px',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          color: 'white',
          cursor: 'pointer',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'space-between',
          minWidth: '80px',
          userSelect: 'none',
          fontSize: '14px'
        }}
      >
        <span>{currentLanguage?.nativeName}</span>
        <span style={{
          marginLeft: '8px',
          transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
          transition: 'transform 0.3s ease'
        }}>▼</span>
      </div>

      {isOpen && (
        <div style={{
          position: 'absolute',
          top: '100%',
          left: '0',
          marginTop: '5px',
          backgroundColor: 'rgba(26, 26, 26, 0.95)',
          borderRadius: '12px',
          backdropFilter: 'blur(10px)',
          border: '1px solid rgba(255, 255, 255, 0.2)',
          overflow: 'hidden',
          width: '100%',
          animation: 'fadeIn 0.2s ease'
        }}>
          {languages.map(lang => (
            <div
              key={lang.code}
              onClick={() => {
                onLanguageChange(lang.code);
                setIsOpen(false);
              }}
              style={{
                padding: '8px 12px',
                color: 'white',
                cursor: 'pointer',
                transition: 'background-color 0.2s ease',
                backgroundColor: lang.code === currentLang ? 'rgba(255, 255, 255, 0.1)' : 'transparent',
                fontSize: '14px'
              }}
            >
              {lang.nativeName}
            </div>
          ))}
        </div>
      )}
    </div>
  );
};

// 分享按钮组件
const ShareButton = ({ isDrawing, similarity, onShare, disabled }) => {
  const { t } = useTranslation();
  const isMobile = window.innerWidth < 768;
  const [isHovered, setIsHovered] = useState(false);
  const [isPressed, setIsPressed] = useState(false);

  return (
    <button
      onClick={onShare}
      disabled={disabled || isDrawing || !similarity}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => {
        setIsHovered(false);
        setIsPressed(false);
      }}
      onMouseDown={() => setIsPressed(true)}
      onMouseUp={() => setIsPressed(false)}
      onTouchStart={() => setIsPressed(true)}
      onTouchEnd={() => setIsPressed(false)}
      style={{
        position: 'absolute',
        bottom: isMobile ? '40px' : '40px',
        left: '50%',
        transform: `translate(-50%, 0) scale(${isPressed ? 0.95 : 1})`,
        backgroundColor: isPressed 
          ? 'rgba(255, 255, 255, 0.05)'
          : isHovered 
            ? 'rgba(255, 255, 255, 0.15)'
            : 'rgba(255, 255, 255, 0.1)',
        border: '1px solid rgba(255, 255, 255, 0.2)',
        borderRadius: isMobile ? '24px' : '20px',
        padding: isMobile ? '14px 24px' : '10px 20px',
        color: 'white',
        cursor: (disabled || isDrawing || !similarity) ? 'default' : 'pointer',
        fontSize: isMobile ? '15px' : '14px',
        fontWeight: '500',
        backdropFilter: 'blur(10px)',
        transition: 'all 0.2s cubic-bezier(0.4, 0, 0.2, 1)',
        userSelect: 'none',
        WebkitUserSelect: 'none',
        WebkitTapHighlightColor: 'transparent',
        touchAction: 'manipulation',
        display: 'flex',
        alignItems: 'center',
        gap: '8px',
        opacity: (disabled || isDrawing) ? 0.5 : 1,
        boxShadow: isMobile 
          ? '0 4px 12px rgba(0,0,0,0.2)' 
          : isHovered 
            ? '0 4px 12px rgba(0,0,0,0.15)'
            : 'none',
        zIndex: 90,
        whiteSpace: 'nowrap',
        minWidth: 'auto',
        maxWidth: isMobile ? '80vw' : '300px',
        overflow: 'hidden',
        textOverflow: 'ellipsis'
      }}
    >
      <span style={{ 
        fontSize: isMobile ? '18px' : '16px',
        transition: 'transform 0.3s ease',
        transform: isPressed ? 'scale(0.9)' : 'scale(1)',
        display: 'inline-block',
        flexShrink: 0
      }}>
        🔗
      </span>
      <span style={{
        overflow: 'hidden',
        textOverflow: 'ellipsis',
        whiteSpace: 'nowrap'
      }}>
        {t('shareButton')}
      </span>
    </button>
  );
};

// 主要内容组件
function MainContent() {
  const { lang } = useParams();
  const { t, i18n } = useTranslation();
  const navigate = useNavigate();
  const canvasRef = useRef(null);
  const [canvasReady, setCanvasReady] = useState(false);
  const isCompletingRef = useRef(false);
  
  // 状态管理
  const [isDrawing, setIsDrawing] = React.useState(false);
  const [points, setPoints] = React.useState([]);
  const [similarity, setSimilarity] = React.useState(null);
  const [isCircleComplete, setIsCircleComplete] = React.useState(false);
  const [totalAttempts, setTotalAttempts] = React.useState(() => 
    parseInt(localStorage.getItem('circleTotalAttempts')) || 0
  );
  const [highScore, setHighScore] = React.useState(() => 
    parseFloat(localStorage.getItem('circleHighScore')) || 0
  );
  const [angleSum, setAngleSum] = React.useState(0);
  const [lastAngle, setLastAngle] = React.useState(null);
  const [showCopiedToast, setShowCopiedToast] = React.useState(false);
  const [shareButtonDisabled, setShareButtonDisabled] = React.useState(false);
  const [isMobile] = useState(() => window.innerWidth < 768);
  const [isNewRecord, setIsNewRecord] = useState(false);

  // 语言切换效果
  useEffect(() => {
    if (lang && i18n.language !== lang) {
      i18n.changeLanguage(lang);
      localStorage.setItem('language', lang);
    }
  }, [lang, i18n]);


  // SEO 更新效果
  useEffect(() => {
    // 等待语言加载完成
    const updateSEO = () => {
      const title = `${t('seo.pageTitle')} | ${t('seo.pageSubtitle')}`;
      document.title = title;
      
      // 更新 meta description
      const metaDescription = document.querySelector('meta[name="description"]');
      if (metaDescription) {
        metaDescription.setAttribute('content', t('seo.metaDescription'));
      }
      
      // 更新 meta keywords
      const metaKeywords = document.querySelector('meta[name="keywords"]');
      if (metaKeywords) {
        metaKeywords.setAttribute('content', t('seo.keywords'));
      }
      
      // 更新 Open Graph 标签
      const ogTitle = document.querySelector('meta[property="og:title"]');
      const ogDescription = document.querySelector('meta[property="og:description"]');
      if (ogTitle) {
        ogTitle.setAttribute('content', t('seo.pageTitle'));
      }
      if (ogDescription) {
        ogDescription.setAttribute('content', t('seo.metaDescription'));
      }
      
      // 更新 Twitter 标签
      const twitterTitle = document.querySelector('meta[property="twitter:title"]');
      const twitterDescription = document.querySelector('meta[property="twitter:description"]');
      if (twitterTitle) {
        twitterTitle.setAttribute('content', t('seo.pageTitle'));
      }
      if (twitterDescription) {
        twitterDescription.setAttribute('content', t('seo.metaDescription'));
      }
    };

    // 确保翻译已加载
    if (i18n.isInitialized && i18n.language === lang) {
      updateSEO();
    }

    const handleLanguageChanged = () => {
      updateSEO();
    };

    i18n.on('languageChanged', handleLanguageChanged);

    return () => {
      i18n.off('languageChanged', handleLanguageChanged);
    };
  }, [t, i18n, lang]);

  // 优化触摸事件处理
  const handleTouchStart = (e) => {
    e.preventDefault();
    const touch = e.touches[0];
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const point = {
      x: touch.clientX - rect.left,
      y: touch.clientY - rect.top
    };
    
    // 重置画布上下文状态
    const ctx = canvas.getContext('2d');
    ctx.beginPath();
    
    handleStart(point);
  };

  const handleTouchMove = (e) => {
    e.preventDefault();
    if (!isDrawing) return;

    const touch = e.touches[0];
    const canvas = canvasRef.current;
    const rect = canvas.getBoundingClientRect();
    const point = {
      x: touch.clientX - rect.left,
      y: touch.clientY - rect.top
    };

    // 使用 requestAnimationFrame 优渲染
    requestAnimationFrame(() => {
      handleMove(point);
    });
  };

  // 先定义 drawCenterPoint 函数
  const drawCenterPoint = useCallback(() => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const ctx = canvas.getContext('2d');
    if (!ctx) return;

    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    
    ctx.beginPath();
    ctx.arc(centerX, centerY, 4, 0, Math.PI * 2);
    ctx.fillStyle = '#FF4444';
    ctx.fill();
    ctx.closePath();
  }, []);

  // 然后再使用它
  useEffect(() => {
    let mounted = true;

    const handleResize = () => {
      if (!mounted) return;
      
      const canvas = canvasRef.current;
      if (!canvas) return;

      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const size = Math.min(vw, vh) * (vw < 768 ? 0.9 : 0.8);

      canvas.width = size;
      canvas.height = size;
      canvas.style.width = `${size}px`;
      canvas.style.height = `${size}px`;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, canvas.width, canvas.height);
        drawCenterPoint();
      }
    };

    const timeoutId = setTimeout(handleResize, 100);

    window.addEventListener('resize', handleResize);

    return () => {
      mounted = false;
      clearTimeout(timeoutId);
      window.removeEventListener('resize', handleResize);
    };
  }, [drawCenterPoint]);

  // 计算角度
  const calculateAngle = (point) => {
    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    return Math.atan2(point.y - centerY, point.x - centerX);
  };

  // 检查点是否在合理范围内
  const isPointAroundCenter = (point) => {
    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    
    const distanceToCenter = Math.sqrt(
      Math.pow(point.x - centerX, 2) + 
      Math.pow(point.y - centerY, 2)
    );

    const minDistance = 10;
    const maxDistance = Math.min(canvas.width, canvas.height) * 0.4;
    
    return distanceToCenter >= minDistance && distanceToCenter <= maxDistance;
  };

  // 检查圆形完成
  const checkCircleCompletion = (currentPoint, points) => {
    if (points.length < 20) return false;

    const currentAngle = calculateAngle(currentPoint);

    if (lastAngle !== null) {
      let angleDiff = currentAngle - lastAngle;
      
      if (angleDiff > Math.PI) angleDiff -= 2 * Math.PI;
      if (angleDiff < -Math.PI) angleDiff += 2 * Math.PI;

      const newAngleSum = angleSum + angleDiff;
      setAngleSum(newAngleSum);

      if (Math.abs(newAngleSum) >= 2 * Math.PI) {
        return true;
      }
    }

    setLastAngle(currentAngle);
    return false;
  };

  // 计算似度
  const calculateCircleSimilarity = (points) => {
    if (points.length < 3) return 0;
    
    const canvas = canvasRef.current;
    const centerX = canvas.width / 2;
    const centerY = canvas.height / 2;
    
    // 计算平均半径
    const radii = points.map(point => 
      Math.sqrt(Math.pow(point.x - centerX, 2) + Math.pow(point.y - centerY, 2))
    );
    const avgRadius = radii.reduce((a, b) => a + b) / radii.length;
    
    // 计算标准差
    const variance = radii.reduce((sum, r) => sum + Math.pow(r - avgRadius, 2), 0) / radii.length;
    const stdDev = Math.sqrt(variance);
    
    // 计算相似度得分 (0-100)
    const similarity = Math.max(0, 100 - (stdDev / avgRadius) * 100);
    return similarity;
  };

  // 修改圆形完成的处理函数
  const handleCircleComplete = () => {
    if (isCompletingRef.current) return;
    isCompletingRef.current = true;
    
    const finalSimilarity = calculateCircleSimilarity(points);
    setSimilarity(finalSimilarity);
    setIsCircleComplete(true);
    setIsDrawing(false);
    
    // 更新总尝试次数和最高分
    setTotalAttempts(prev => {
      const newValue = prev + 1;
      localStorage.setItem('circleTotalAttempts', newValue.toString());
      return newValue;
    });

    if (finalSimilarity > highScore) {
      setHighScore(finalSimilarity);
      localStorage.setItem('circleHighScore', finalSimilarity.toString());
      setIsNewRecord(true);
      
      setTimeout(() => {
        setIsNewRecord(false);
      }, 3000);
    }
  };

  // 修改鼠标/触摸开始处理函数
  const handleStart = (point) => {
    setIsDrawing(true);
    setPoints([point]);
    setAngleSum(0);
    setLastAngle(calculateAngle(point));
    setSimilarity(null);
    setIsCircleComplete(false);
    isCompletingRef.current = false;

    const ctx = canvasRef.current.getContext('2d');
    ctx.clearRect(0, 0, canvasRef.current.width, canvasRef.current.height);
    drawCenterPoint();
    
    // 创建渐变
    const gradient = ctx.createLinearGradient(0, 0, canvasRef.current.width, canvasRef.current.height);
    gradient.addColorStop(0, '#FF1493');
    gradient.addColorStop(0.2, '#FF4500');
    gradient.addColorStop(0.4, '#32CD32');
    gradient.addColorStop(0.6, '#00CED1');
    gradient.addColorStop(0.8, '#9370DB');
    gradient.addColorStop(1, '#FF1493');

    // 设置线条样式
    ctx.beginPath();
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.strokeStyle = gradient;
    ctx.moveTo(point.x, point.y);
  };

  // 修改鼠标/触摸移动处理函数
  const handleMove = (point) => {
    if (!isDrawing || isCircleComplete) return;
    
    if (!isPointAroundCenter(point)) {
      setIsDrawing(false);
      setAngleSum(0);
      setLastAngle(null);
      return;
    }

    const ctx = canvasRef.current.getContext('2d');
    
    setPoints(prevPoints => {
      const newPoints = [...prevPoints, point];
      if (newPoints.length > 3) {
        const currentSimilarity = calculateCircleSimilarity(newPoints);
        setSimilarity(currentSimilarity);
        
        if (checkCircleCompletion(point, newPoints)) {
          handleCircleComplete();
        }
      }
      return newPoints;
    });

    // 创建渐变
    const gradient = ctx.createLinearGradient(0, 0, canvasRef.current.width, canvasRef.current.height);
    gradient.addColorStop(0, '#FF1493');    // 深粉红
    gradient.addColorStop(0.2, '#FF4500');  // 橙红色
    gradient.addColorStop(0.4, '#32CD32');  // 酸橙绿
    gradient.addColorStop(0.6, '#00CED1');  // 深天蓝
    gradient.addColorStop(0.8, '#9370DB');  // 中紫色
    gradient.addColorStop(1, '#FF1493');    // 深粉红

    // 设置线条样式
    ctx.lineWidth = 4;
    ctx.lineCap = 'round';
    ctx.lineJoin = 'round';
    ctx.strokeStyle = gradient;
    ctx.lineTo(point.x, point.y);
    ctx.stroke();
  };

  // 统一的事件处理函数
  const handleMouseDown = (e) => {
    const rect = canvasRef.current.getBoundingClientRect();
    handleStart({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    });
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    const rect = canvasRef.current.getBoundingClientRect();
    handleMove({
      x: e.clientX - rect.left,
      y: e.clientY - rect.top
    });
  };

  // 语言切换理
  const handleLanguageChange = (newLang) => {
    navigate(`/${newLang}`);
  };

  // 优化分享处理函数
  const handleShare = async () => {
    if (isDrawing || !similarity || shareButtonDisabled) return;
    setShareButtonDisabled(true);

    // 使用模板字符串确保正确的换行
    const shareText = `${t('shareText.score', { score: similarity.toFixed(1) })}

${t('shareText.highScore', { score: highScore.toFixed(1) })}
${t('shareText.attempts', { count: totalAttempts })}

${t('shareText.invitation')}

${window.location.href}`;

    try {
      if (navigator.share && /mobile|android|ios/i.test(navigator.userAgent)) {
        await navigator.share({
          title: t('shareTitle'),
          text: shareText,
          url: window.location.href
        });
      } else {
        await navigator.clipboard.writeText(shareText);
        setShowCopiedToast(true);
        if (navigator.vibrate) navigator.vibrate(50);
        setTimeout(() => setShowCopiedToast(false), 2000);
      }
    } catch (error) {
      console.error('Share failed:', error);
    } finally {
      setTimeout(() => setShareButtonDisabled(false), 1000);
    }
  };

  // Canvas 初始化
  useEffect(() => {
    const initCanvas = () => {
      const canvas = canvasRef.current;
      if (!canvas) return false;

      const vw = Math.max(document.documentElement.clientWidth || 0, window.innerWidth || 0);
      const vh = Math.max(document.documentElement.clientHeight || 0, window.innerHeight || 0);
      const size = Math.min(vw, vh) * (vw < 768 ? 0.9 : 0.8);

      canvas.width = size;
      canvas.height = size;
      canvas.style.width = `${size}px`;
      canvas.style.height = `${size}px`;

      const ctx = canvas.getContext('2d');
      if (ctx) {
        ctx.clearRect(0, 0, size, size);
        // 绘制中心点
        ctx.beginPath();
        ctx.arc(size/2, size/2, 4, 0, Math.PI * 2);
        ctx.fillStyle = '#FF4444';
        ctx.fill();
        ctx.closePath();
      }

      return true;
    };

    // 等待 DOM 完全加载
    if (document.readyState === 'complete') {
      const success = initCanvas();
      setCanvasReady(success);
    } else {
      window.addEventListener('load', () => {
        const success = initCanvas();
        setCanvasReady(success);
      });
    }

    // 处理窗口大小变化
    const handleResize = () => {
      if (canvasReady) {
        initCanvas();
      }
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, [canvasReady]);

  return (
    <div className="App" style={{
      backgroundColor: '#111111',
      minHeight: '100vh',
      overflow: 'hidden',
      position: 'relative',
      display: 'flex',
      flexDirection: 'column',
      touchAction: 'none',
      userSelect: 'none'
    }}>
      <Helmet>
        <html lang={lang} />
        <title>{t('seo.pageTitle')} | {t('seo.pageSubtitle')}</title>
        <meta name="description" content={t('seo.metaDescription')} />
        <meta name="keywords" content={t('seo.keywords')} />
        
        {/* Open Graph / Facebook */}
        <meta property="og:type" content="website" />
        <meta property="og:url" content={`https://circle-drawing.pages.dev/${lang}`} />
        <meta property="og:title" content={t('seo.pageTitle')} />
        <meta property="og:description" content={t('seo.metaDescription')} />
        
        {/* Twitter */}
        <meta property="twitter:card" content="summary_large_image" />
        <meta property="twitter:url" content={`https://circle-drawing.pages.dev/${lang}`} />
        <meta property="twitter:title" content={t('seo.pageTitle')} />
        <meta property="twitter:description" content={t('seo.metaDescription')} />
        
        {/* Canonical URL */}
        <link rel="canonical" href={`https://circle-drawing.pages.dev/${lang}`} />
        
        {/* Alternate language versions */}
        {languages.map(({ code }) => (
          <link 
            key={code}
            rel="alternate" 
            href={`https://circle-drawing.pages.dev/${code}`}
            hreflang={code}
          />
        ))}
        <link 
          rel="alternate" 
          href="https://circle-drawing.pages.dev/en" 
          hreflang="x-default"
        />
      </Helmet>

      {/* 桌面端语言换器 */}
      {!isMobile && (
        <div style={{
          position: 'absolute',
          top: '20px',
          left: '20px',
          zIndex: 90,
          display: 'flex',
          gap: '10px'
        }}>
      <LanguageSwitcher 
        currentLang={lang} 
            onLanguageChange={handleLanguageChange}
          />
        </div>
      )}

      {/* 使用说明 */}
      <div style={{
        position: 'absolute',
        top: isMobile ? '80px' : '80px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 80,
        backgroundColor: 'rgba(255, 255, 255, 0.05)',
        padding: isMobile ? '12px 20px' : '10px 20px',
        borderRadius: '12px',
        backdropFilter: 'blur(10px)',
        border: '1px solid rgba(255, 255, 255, 0.1)',
        color: 'rgba(255, 255, 255, 0.8)',
        fontSize: isMobile ? '15px' : '14px',
        textAlign: 'center',
        maxWidth: '90%',
        width: isMobile ? 'auto' : '300px',
        lineHeight: '1.5',
        pointerEvents: 'none',
        whiteSpace: 'pre-line',
        boxShadow: '0 2px 8px rgba(0,0,0,0.1)',
        opacity: isDrawing ? 0.3 : 1,
        transition: 'opacity 0.3s ease'
      }}>
        {t('instruction')}
      </div>

      {/* Canvas */}
      <canvas 
        ref={canvasRef}
        style={{ 
          position: 'absolute',
          top: '50%',
          left: '50%',
          transform: 'translate(-50%, -50%)',
          touchAction: 'none',
          zIndex: isDrawing ? 100 : 1,
          opacity: canvasReady ? 1 : 0,  // 在 canvas 准备好之前保持透明
          transition: 'opacity 0.3s ease'
        }}
        onMouseDown={handleMouseDown}
        onMouseMove={handleMouseMove}
        onMouseUp={() => setIsDrawing(false)}
        onMouseLeave={() => setIsDrawing(false)}
        onTouchStart={handleTouchStart}
        onTouchMove={handleTouchMove}
        onTouchEnd={() => setIsDrawing(false)}
        onTouchCancel={() => setIsDrawing(false)}
      />

      {/* 分享按钮容器 */}
      <div style={{
        position: 'absolute',
        bottom: isMobile ? '40px' : '40px',
        left: '50%',
        transform: 'translateX(-50%)',
        zIndex: 90,
        display: 'flex',
        gap: '15px'
      }}>
      <ShareButton
        isDrawing={isDrawing}
        similarity={similarity}
        disabled={shareButtonDisabled}
        onShare={handleShare}
      />
        {isNewRecord && (
          <div style={{
            position: 'absolute',
            top: '-30px',
            left: '50%',
            transform: 'translateX(-50%)',
            color: '#FFD700',
            fontSize: '14px',
            animation: 'bounce 1s infinite',
            whiteSpace: 'nowrap'
          }}>
            🏆 {t('newRecord')}
          </div>
        )}
      </div>

      {/* 复制成功提示 */}
      {showCopiedToast && (
        <div style={{
          position: 'fixed',
          bottom: isMobile ? '120px' : '100px',
          left: '50%',
          transform: 'translateX(-50%)',
          backgroundColor: 'rgba(76, 175, 80, 0.95)',
          color: 'white',
          padding: isMobile ? '12px 24px' : '10px 20px',
          borderRadius: '20px',
          fontSize: isMobile ? '15px' : '14px',
          zIndex: 1000,
          animation: 'fadeInUp 0.3s ease',
          display: 'flex',
          alignItems: 'center',
          gap: '8px',
          backdropFilter: 'blur(10px)',
          boxShadow: '0 4px 12px rgba(0,0,0,0.2)',
          border: '1px solid rgba(255, 255, 255, 0.1)'
        }}>
          <span style={{ 
            animation: 'spin 1s ease',
            display: 'inline-block'
          }}>✨</span>
          {t('copied')}
          <span style={{ 
            animation: 'spin 1s ease reverse',
            display: 'inline-block'
          }}>✨</span>
        </div>
      )}

      {/* 分数显示 */}
      {similarity !== null && (
        <div style={{
          position: 'absolute',
          top: isMobile ? '160px' : '160px',
          left: '50%',
          transform: 'translateX(-50%)',
          zIndex: 80,
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          gap: '10px'
        }}>
          {/* 当前分数 */}
          <div style={{
            backgroundColor: 'rgba(255, 255, 255, 0.1)',
            padding: isMobile ? '16px 32px' : '12px 24px',
            borderRadius: '16px',
            backdropFilter: 'blur(10px)',
            border: '1px solid rgba(255, 255, 255, 0.2)',
            color: 'white',
            fontSize: isMobile ? '24px' : '20px',
            fontWeight: '500',
            textAlign: 'center',
            minWidth: '140px',
            boxShadow: '0 4px 12px rgba(0,0,0,0.1)'
          }}>
            {similarity.toFixed(1)}%
          </div>

          {/* 最高分和尝试次数 */}
          <div style={{
            display: 'flex',
            gap: '10px',
            fontSize: isMobile ? '14px' : '13px',
            color: 'rgba(255, 255, 255, 0.7)'
          }}>
            <span>🏆 {highScore.toFixed(1)}%</span>
            <span>·</span>
            <span>🔄 {totalAttempts}</span>
          </div>
        </div>
      )}

      {/* 添加动画样式 */}
      <style>
        {`
          @keyframes fadeInUp {
            from {
              opacity: 0;
              transform: translate(-50%, 20px);
            }
            to {
              opacity: 1;
              transform: translate(-50%, 0);
            }
          }

          @keyframes spin {
            from {
              transform: rotate(0deg) scale(1);
            }
            to {
              transform: rotate(360deg) scale(1.2);
            }
          }

          @keyframes ripple {
            from {
              transform: scale(0);
              opacity: 1;
            }
            to {
              transform: scale(2);
              opacity: 0;
            }
          }

          @media (hover: hover) {
            button:hover:not(:disabled) {
              transform: translateY(-1px);
            }
          }

          @media (max-width: 768px) {
            body {
              overscroll-behavior: none;
            }

            button:active:not(:disabled) {
              transform: scale(0.95) !important;
            }
          }
        `}
      </style>
    </div>
  );
}

// 主应用组件
function App() {
  return (
    <HelmetProvider>
      <BrowserRouter>
        <Routes>
          <Route 
            path="/" 
            element={<Navigate to={`/${localStorage.getItem('language') || 'en'}`} replace />} 
          />
          <Route path="/:lang" element={<MainContent />} />
          <Route path="*" element={<Navigate to="/" replace />} />
        </Routes>
      </BrowserRouter>
    </HelmetProvider>
  );
}

export default App;
