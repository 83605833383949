const zhTranslations = {
  translation: {
    title: '完美圆形挑战',
    instruction: '在红点周围画一个完美的圆形\n尽可能保持稳定和均匀',
    totalAttempts: '总尝试',
    highScore: '最高分',
    newRecord: '新纪录！',
    shareButton: '分享成绩',
    shareTitle: '完美圆形挑战',
    shareText: {
      score: '🎯 我在完美圆形挑战中画出了 {{score}}% 的相似度！',
      highScore: '🏆 我的最高分：{{score}}%',
      attempts: '🔄 总尝试次数：{{count}}',
      invitation: '来挑战我吧 👇'
    },
    copied: '已复制到剪贴板 ✨',
    copyright: '© 2024 完美圆形挑战。保留所有权利。',
    seo: {
      pageTitle: '完美圆形挑战',
      pageSubtitle: '测试手绘精准度 | 趣味绘画游戏',
      metaDescription: '挑战自己画出完美圆形！这个创意游戏通过AI分析评分，测试你的手绘精准度。支持多语言，全球玩家同台竞技，快来挑战自己的绘画技巧。',
      keywords: '完美圆形,画圆游戏,手绘测试,绘画挑战,创意游戏,手眼协调,圆形绘制,精准度测试,在线游戏,互动游戏'
    }
  },
  
};
export default zhTranslations;

