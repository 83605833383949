import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import LanguageDetector from 'i18next-browser-languagedetector';

import enTranslations from './i18n/locales/en';
import zhTranslations from './i18n/locales/zh';
import jaTranslations from './i18n/locales/ja';
import koTranslations from './i18n/locales/ko';
import frTranslations from './i18n/locales/fr';
import esTranslations from './i18n/locales/es';
import ruTranslations from './i18n/locales/ru';
import thTranslations from './i18n/locales/th';

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    resources: {
      en: enTranslations,
      zh: zhTranslations,
      ja: jaTranslations,
      ko: koTranslations,
      fr: frTranslations,
      es: esTranslations,
      ru: ruTranslations,
      th: thTranslations
    },
    fallbackLng: 'en',
    interpolation: {
      escapeValue: false
    }
  });

export default i18n; 