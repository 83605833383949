const esTranslations = {
  translation: {
    title: 'Desafío del Círculo Perfecto',
    instruction: 'Dibuja un círculo perfecto alrededor del punto central rojo',
    totalAttempts: 'Intentos totales',
    highScore: 'Mejor puntuación',
    newRecord: '¡Nuevo récord!',
    shareButton: 'Compartir puntuación',
    shareTitle: 'Desafío del Círculo Perfecto',
    shareText: {
      score: '🎯 ¡Logré {{score}}% de similitud en el Desafío del Círculo Perfecto!',
      highScore: '🏆 Mi mejor puntuación: {{score}}%',
      attempts: '🔄 Intentos totales: {{count}}',
      invitation: '¡Desafíame! 👇'
    },
    copied: 'Copiado al portapapeles ✨',
    copyright: '© 2024 Desafío del Círculo Perfecto. Todos los derechos reservados.',
    seo: {
        pageTitle: 'Desafío del Círculo Perfecto',
        pageSubtitle: 'Prueba tu precisión | Juego creativo',
        metaDescription: '¡Acepta el desafío de dibujar el círculo perfecto! Obtén puntuaciones precisas al instante con IA en este juego creativo. Disponible en varios idiomas.',
        keywords: 'círculo perfecto,juego de dibujo,prueba manual,desafío dibujo,juego creativo,prueba de precisión,dibujo de círculo,juego de arte,juego en línea,juego interactivo'
      }  
  }
};

export default esTranslations; 