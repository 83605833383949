const koTranslations = {
  translation: {
    title: '완벽한 원 챌린지',
    instruction: '빨간색 중심점 주위에 완벽한 원을 그리세요',
    totalAttempts: '총 시도 횟수',
    highScore: '최고 점수',
    newRecord: '새로운 기록!',
    shareButton: '점수 공유',
    shareTitle: '완벽한 원 챌린지',
    shareText: {
      score: '🎯 완벽한 원 챌린지에서 {{score}}% 유사도를 달성했어요!',
      highScore: '🏆 최고 점수: {{score}}%',
      attempts: '🔄 총 시도 횟수: {{count}}',
      invitation: '도전해보세요! 👇'
    },
    copied: '클립보드에 복사되었습니다 ✨',
    copyright: '© 2024 완벽한 원 챌린지. 모든 권리 보유.',
    seo: {
      pageTitle: '완벽한 원 챌린지',
      pageSubtitle: '그리기 정확도 테스트 | 아트 게임',
      metaDescription: '완벽한 원 그리기에 도전하세요! AI 분석으로 그리기 정확도를 측정하는 창의적인 게임입니다. 전 세계 플레이어들과 실력을 겨루며 그리기 능력을 테스트해보세요.',
      keywords: '완벽한 원,그리기 게임,손그림 테스트,그리기 도전,창의적 게임,정확도 테스트,원 그리기,아트 게임,온라인 게임,인터랙티브 게임'
    }
  },
  
};

export default koTranslations;
