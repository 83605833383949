const ruTranslations = {
  translation: {
    title: 'Испытание Идеального Круга',
    instruction: 'Нарисуйте идеальный круг вокруг красной центральной точки',
    totalAttempts: 'Всего попыток',
    highScore: 'Рекорд',
    newRecord: 'Новый рекорд!',
    shareButton: 'Поделиться результатом',
    shareTitle: 'Испытание Идеального Круга',
    shareText: {
      score: '🎯 Я достиг {{score}}% сходства в Испытании Идеального Круга!',
      highScore: '🏆 Мой рекорд: {{score}}%',
      attempts: '🔄 Всего попыток: {{count}}',
      invitation: 'Брось мне вызов! 👇'
    },
    copied: 'Скопировано в буфер обмена ✨',
    copyright: '© 2024 Испытание Идеального Круга. Все права защищены.',
    seo: {
        pageTitle: 'Испытание Идеального Круга',
        pageSubtitle: 'Проверь свою точность | Творческая игра',
        metaDescription: 'Примите вызов нарисовать идеальный круг! Получайте мгновенную оценку точности с помощью ИИ в этой творческой игре. Доступно на разных языках.',
        keywords: 'идеальный круг,игра рисование,тест рисования,испытание,творческая игра,тест точности,рисование круга,арт игра,онлайн игра,интерактивная игра'
      }
},
  
};

export default ruTranslations; 