const jaTranslations = {
  translation: {
    title: '円描きチャレンジ',
    instruction: '赤い中心点の周りに完璧な円を描いてください',
    totalAttempts: '総試行回数',
    highScore: '最高スコア',
    newRecord: '新記録！',
    shareButton: 'スコアをシェア',
    shareTitle: '円描きチャレンジ',
    shareText: {
      score: '🎯 円描きチャレンジで{{score}}%の類似度を達成しました！',
      highScore: '🏆 自己ベスト：{{score}}%',
      attempts: '🔄 総試行回数：{{count}}',
      invitation: 'チャレンジしてみよう！👇'
    },
    copied: 'クリップボードにコピーしました ✨',
    copyright: '© 2024 円描きチャレンジ。全著作権所有。',
    seo: {
      pageTitle: '円描きチャレンジ',
      pageSubtitle: '描画精度テスト | アートゲーム',
      metaDescription: '円を描くチャレンジに挑戦！AIによる精密なスコア分析で描画スキルをテスト。多言語対応で世界中のプレイヤーと競争できる創造的なゲーム。',
      keywords: '円描き,描画ゲーム,手書きテスト,描画チャレンジ,創造的ゲーム,精度テスト,アートゲーム,オンラインゲーム,インタラクティブ'
    }
  },
  
};

export default jaTranslations;
