const frTranslations = {
  translation: {
    title: 'Défi du Cercle Parfait',
    instruction: 'Dessinez un cercle parfait autour du point central rouge',
    totalAttempts: 'Tentatives totales',
    highScore: 'Meilleur score',
    newRecord: 'Nouveau record !',
    shareButton: 'Partager le score',
    shareTitle: 'Défi du Cercle Parfait',
    shareText: {
      score: '🎯 J\'ai obtenu {{score}}% de similitude dans le Défi du Cercle Parfait !',
      highScore: '🏆 Mon meilleur score : {{score}}%',
      attempts: '🔄 Tentatives totales : {{count}}',
      invitation: 'Défiez-moi ! 👇'
    },
    copied: 'Copié dans le presse-papiers ✨',
    copyright: '© 2024 Défi du Cercle Parfait. Tous droits réservés.',
    seo: {
        pageTitle: 'Défi du Cercle Parfait',
        pageSubtitle: 'Testez votre précision | Jeu créatif',
        metaDescription: 'Relevez le défi de dessiner le cercle parfait ! Obtenez des scores de précision instantanés grâce à l\'IA dans ce jeu créatif. Disponible en plusieurs langues.',
        keywords: 'cercle parfait,jeu de dessin,test manuel,défi dessin,jeu créatif,test de précision,dessin de cercle,jeu d\'art,jeu en ligne,jeu interactif'
      }
},
  
};

export default frTranslations; 