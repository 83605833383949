const enTranslations = {
  translation: {
    title: 'Perfect Circle Challenge',
    instruction: 'Draw a perfect circle around the red center point',
    totalAttempts: 'Total Attempts',
    highScore: 'High Score',
    newRecord: 'New Record!',
    shareButton: 'Share Score',
    shareTitle: 'Perfect Circle Challenge',
    shareText: {
      score: '🎯 I achieved {{score}}% similarity in the Perfect Circle Challenge!',
      highScore: '🏆 My Best Score: {{score}}%',
      attempts: '🔄 Total Attempts: {{count}}',
      invitation: 'Challenge me! 👇'
    },
    copied: 'Copied to clipboard ✨',
    copyright: '© 2024 Perfect Circle Challenge. All rights reserved.',
    seo: {
      pageTitle: 'Perfect Circle Challenge',
      pageSubtitle: 'Test Your Drawing Skills | Fun Art Game',
      metaDescription: 'Challenge yourself to draw the perfect circle! Get instant AI-powered accuracy scores in this creative game. Available in multiple languages, compete globally and test your drawing precision.',
      keywords: 'perfect circle,drawing game,hand drawing test,drawing challenge,creative game,precision test,circle drawing,art game,online game,interactive game'
    }
  },
  
};

export default enTranslations;
