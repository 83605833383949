const thTranslations = {
  translation: {
    title: 'ความท้าทายวงกลมสมบูรณ์แบบ',
    instruction: 'วาดวงกลมสมบูรณ์แบบรอบจุดศูนย์กลางสีแดง',
    totalAttempts: 'จำนวนครั้งที่ลอง',
    highScore: 'คะแนนสูงสุด',
    newRecord: 'สถิติใหม่!',
    shareButton: 'แชร์คะแนน',
    shareTitle: 'ความท้าทายวงกลมสมบูรณ์แบบ',
    shareText: {
      score: '🎯 ฉันได้คะแนนความเหมือน {{score}}% ในความท้าทายวงกลมสมบูรณ์แบบ!',
      highScore: '🏆 คะแนนสูงสุดของฉัน: {{score}}%',
      attempts: '🔄 จำนวนครั้งที่ลอง: {{count}}',
      invitation: 'มาท้าทายฉันสิ! 👇'
    },
    copied: 'คัดลอกไปยังคลิปบอร์ดแล้ว ✨',
    copyright: '© 2024 ความท้าทายวงกลมสมบูรณ์แบบ สงวนลิขสิทธิ์',
    seo: {
        pageTitle: 'ความท้าทายวงกลมสมบูรณ์แบบ',
        pageSubtitle: 'ทดสอบความแม่นยำ | เกมสร้างสรรค์',
        metaDescription: 'ท้าทายตัวเองในการวาดวงกลมที่สมบูรณ์แบบ! รับคะแนนความแม่นยำทันทีด้วย AI ในเกมสร้างสรรค์นี้ มีให้บริการในหลายภาษา',
        keywords: 'วงกลมสมบูรณ์แบบ,เกมวาดรูป,ทดสอบการวาด,ความท้าทาย,เกมสร้างสรรค์,ทดสอบความแม่นยำ,การวาดวงกลม,เกมศิลปะ,เกมออนไลน์,เกมโต้ตอบ'
      }
  },
  
};

export default thTranslations; 